import { useMemo } from 'react'

import GamesLibrary, { allGameSlugs, unplayableOnSafariGameSlugs } from '~/gameConfigs/GamesLibrary'
import useLocale from '~/hooks/useLocale'

export const useAvailableGames = () => {
  const { locale } = useLocale()
  const { isSafari } = require('react-device-detect')

  const filteredGameSlugs = useMemo(() => {
    const gameSlugsWithLanguageFilter = locale.startsWith('en')
      ? allGameSlugs
      : GamesLibrary.filter((game) => !game.isEnglishOnly).map((game) => game.slug)

    return gameSlugsWithLanguageFilter.filter((slug) => !(isSafari && unplayableOnSafariGameSlugs.includes(slug)))
  }, [locale, isSafari])

  return filteredGameSlugs
}
