import { forwardRef } from 'react'

import { SnackbarContent, SnackbarKey } from 'notistack'
import styled from 'styled-components'

import Card from '~/components/ui/Card'

interface LeafBubbleCardProps {
  id?: SnackbarKey
  children: React.ReactNode | React.ReactNode[]
  className?: string
}

/**
 * Responsive notification container. Positions semi-transparent leaves on background.
 * Should be styled with `styled(LeafBubbleCard)` in order to color background.
 * Renders provided children.
 */
const LeafBubbleCard = forwardRef<HTMLDivElement, LeafBubbleCardProps>(
  ({ id, children, className, ...otherProps }, ref) => {
    return (
      <SnackbarContent ref={ref} id={id as string} {...otherProps} style={{ display: 'grid' }}>
        <SnackbarContainer className={className}>
          <LeavesAndBubbles />
          {children}
        </SnackbarContainer>
      </SnackbarContent>
    )
  },
)

const LeavesAndBubbles = () => {
  return (
    <ImageContainer>
      <TopLeaves>
        <use href='#top-leaves' width='100%' height='100%' />
      </TopLeaves>
      <BottomLeaves>
        <use href='#bottom-leaves' width='100%' height='100%' />
      </BottomLeaves>
      <Bubbles>
        <use href='#bubbles' width='100%' height='100%' />
      </Bubbles>
    </ImageContainer>
  )
}

export default LeafBubbleCard

const ImageContainer = styled.div`
  grid-area: 1 / 1 / -1 / -1;
`

const TopLeaves = styled.svg.attrs({
  viewBox: '0 0 57 49',
  height: '50px',
})`
  position: absolute;
  right: 0;
  top: 0;
  border-top-right-radius: 16px;
  max-height: 120px;
`

const Bubbles = styled.svg.attrs({
  viewBox: '0 0 81 79',
  height: '65px',
})`
  position: absolute;
  left: 0;
  top: 50%;
  /* center bubbles vertically */
  transform: translateY(-50%);
  max-height: 120px;
`

const BottomLeaves = styled.svg.attrs({
  viewBox: '0 0 60 30',
  height: '35px',
})`
  position: absolute;
  left: 50%;
  bottom: 0;
  max-height: 120px;
`

const SnackbarContainer = styled(Card)`
  grid-area: 1 / -1 / 1 / -1;
  color: ${({ theme }) => theme.colors.neutral[400]};
  padding: 24px;
  display: grid;
  justify-items: center;
  max-width: 640px;
  min-height: 50px;

  > *:not(:first-child) {
    /* ensure all {children} content is above background images */
    z-index: 1;
  }
`
