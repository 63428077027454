import React, { useContext, useState } from 'react'

import { useReactiveVar } from '@apollo/client'
import { BodyText1SemiBold } from '@lumoslabs/lumosity-storybook'
import Tippy from '@tippyjs/react/headless'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSnackbar } from 'notistack'
import { useCookies } from 'react-cookie'
import styled from 'styled-components'

import LogoutConfirmation from '~/components/snackbars/LogoutConfirmation'
import StreakCounter from '~/components/streaks/StreakCounter'
import ButtonBackToGames from '~/components/ui/ButtonBackToGames'
import ButtonQuitGame from '~/components/ui/ButtonQuitGame'
import ButtonQuitWorkout from '~/components/ui/ButtonQuitWorkout'
import DropdownBox from '~/components/ui/DropdownBox'
import WorkoutHeader from '~/components/workout/WorkoutHeader'
import { HeaderTypes, LUMOSITY_USER_COOKIE } from '~/constants'
import { AuthContext } from '~/context/AuthContext'
import { useGetTodaysWorkoutQuery } from '~/graphql/generated/schema'
import { currentUserVar } from '~/graphql/reactive-vars'
import { useAvailableGames } from '~/hooks/useAvailableGames'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'
import useUpdateAccountState from '~/hooks/useUpdateAccountState'
import ChevronDown from '~/images/ChevronDown.svg'
import ChevronUp from '~/images/ChevronUp.svg'
import Close from '~/images/Close.svg'
import Hamburger from '~/images/Hamburger.svg'
import Help from '~/images/Help.svg'
import LogOut from '~/images/LogOut.svg'
import { getDate } from '~/utils/getDate'

const USER_GUIDE_URL = 'https://tinyurl.com/playtargettau-guide'

enum ButtonTypes {
  lumos = 'lumos',
  lumosWhite = 'lumosWhite',
  freeplayPreGame = 'freeplayPreGame',
  freeplayPostGame = 'freeplayPostGame',
  quitWorkoutWhite = 'quitWorkoutWhite',
  stepGame = 'stepGame',
  empty = 'empty',
  quitWorkoutGrey = 'quitWorkoutGrey',
}

export interface TopNavProps {
  headerType?: HeaderTypes
  onNavButtonClick?: () => void
}

const HeaderNavButton = ({
  buttonType,
  onNavButtonClick,
}: {
  buttonType: ButtonTypes
  onNavButtonClick: () => void
}) => {
  switch (buttonType) {
    case ButtonTypes.empty:
      return <></>
    case ButtonTypes.lumos:
      return (
        <Link href='/' passHref>
          <StyledBrandTitle />
        </Link>
      )
    case ButtonTypes.lumosWhite:
      return (
        <Link href='/' passHref>
          <StyledBrandTitle $isWhite={true} />
        </Link>
      )
    case ButtonTypes.freeplayPreGame:
      return (
        <Link href='/games' passHref>
          <ButtonBackToGames $isWhite={true} />
        </Link>
      )
    case ButtonTypes.freeplayPostGame:
      return (
        <Link href='/games' passHref>
          <ButtonBackToGames />
        </Link>
      )
    case ButtonTypes.quitWorkoutWhite:
      return (
        <Link href='/' passHref>
          <ButtonQuitWorkout $isWhite={true} />
        </Link>
      )
    case ButtonTypes.quitWorkoutGrey:
      return (
        <Link href='/' passHref>
          <ButtonQuitWorkout />
        </Link>
      )
    case ButtonTypes.stepGame:
      return <ButtonQuitGame onClick={onNavButtonClick} />
    default:
      return <></>
  }
}

const getHeaderConfig = (headerType: HeaderTypes) => {
  // Add header types to this list that shouldn't have the header stuck to the top of the page when you scroll down
  const sticky = ![
    HeaderTypes.login,
    HeaderTypes.survey,
    HeaderTypes.postWorkoutGame,
    HeaderTypes.preWorkoutGame,
    HeaderTypes.subscribe,
  ].includes(headerType)

  const showStreaks = false

  // Add header types here that should show your name with the dropdown menu
  const showName = [HeaderTypes.lumos, HeaderTypes.preFitTestGame, HeaderTypes.subscribe, HeaderTypes.survey].includes(
    headerType,
  )

  // Show white text for Fit Test header name and logo
  const isFitTest = headerType === HeaderTypes.preFitTestGame
  // Add header types here that should show the workout progress donut
  const showWorkout = [HeaderTypes.preWorkoutGame, HeaderTypes.postWorkoutGame].includes(headerType)

  // Add header types here that should have a transparent background
  const BaseContainer = [
    HeaderTypes.freeplayPreGame,
    HeaderTypes.freeplayPostGame,
    HeaderTypes.preWorkoutGame,
    HeaderTypes.postWorkoutGame,
    HeaderTypes.preFitTestGame,
    HeaderTypes.survey,
    HeaderTypes.stepGame,
    HeaderTypes.subscribe,
  ].includes(headerType)
    ? ContainerTransparent
    : ContainerPrimary

  let primaryButton = ButtonTypes.lumos
  // Add custom button types here
  if (headerType) {
    if (headerType === HeaderTypes.freeplayPostGame) {
      primaryButton = ButtonTypes.freeplayPostGame
    } else if (headerType === HeaderTypes.freeplayPreGame) {
      primaryButton = ButtonTypes.freeplayPreGame
    } else if (headerType === HeaderTypes.empty) {
      primaryButton = ButtonTypes.empty
    } else if (headerType === HeaderTypes.stepGame) {
      primaryButton = ButtonTypes.stepGame
    } else if (headerType === HeaderTypes.preWorkoutGame) {
      primaryButton = ButtonTypes.quitWorkoutWhite
    } else if (headerType === HeaderTypes.postWorkoutGame) {
      primaryButton = ButtonTypes.quitWorkoutGrey
    } else if (headerType === HeaderTypes.preFitTestGame) {
      primaryButton = ButtonTypes.lumosWhite
    }
  }

  return {
    sticky,
    showStreaks,
    showName,
    isFitTest,
    showWorkout,
    BaseContainer,
    primaryButton,
  }
}

const TopNav: React.FC<TopNavProps> = ({ headerType = HeaderTypes.lumos, onNavButtonClick }) => {
  const t = useTranslationForNamespace('common')
  const [, , removeCookie] = useCookies()
  const { enqueueSnackbar } = useSnackbar()

  const router = useRouter()
  const { hasAccountStateOverride } = useContext(AuthContext)

  useUpdateAccountState({ skip: hasAccountStateOverride })

  const currentUser = useReactiveVar(currentUserVar)
  const name = currentUser?.username || '...'

  // logout directly via request to custom session termination endpoint
  const handleLogoutInApp = () => {
    const handleLogoutConfirmation = async () => {
      removeCookie(LUMOSITY_USER_COOKIE)
    }

    enqueueSnackbar(t('notifications.logoutConfirmation'), {
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      content: (key, message) => (
        <LogoutConfirmation id={key} message={message} handleConfirm={handleLogoutConfirmation} />
      ),
    })
  }

  const availableGameSlugs = useAvailableGames()
  const { data } = useGetTodaysWorkoutQuery({
    variables: { knownGameSlugs: availableGameSlugs, currentDate: getDate() },
    skip: ![HeaderTypes.postWorkoutGame, HeaderTypes.preWorkoutGame, HeaderTypes.preFitTestGame].includes(headerType),
  })

  const [visible, setVisible] = useState(false)
  const show = () => setVisible(true)
  const hide = () => setVisible(false)

  const { sticky, showName, showStreaks, showWorkout, BaseContainer, primaryButton, isFitTest } =
    getHeaderConfig(headerType)

  return (
    <BaseContainer sticky={sticky} noTopNav={headerType === HeaderTypes.noTopNav}>
      <HeaderNavButton buttonType={primaryButton} onNavButtonClick={onNavButtonClick || router.back} />
      <StreakAndNameContainer>
        {showStreaks && (
          <StreakContainer>
            <StreakCounter />
          </StreakContainer>
        )}
        {showName && (
          <Tippy
            visible={visible}
            interactive={true}
            onClickOutside={hide}
            placement='bottom-start'
            render={(attrs) => (
              <div {...attrs}>
                <DropdownBox paddingHorizontal={0} paddingVertical={0} placement={attrs['data-placement']}>
                  <DropdownItemsContainer>
                    <a href={USER_GUIDE_URL} target='_blank' rel='noreferrer'>
                      <FirstDropDownRow>
                        <Help title='User Guide' height='27px' width='27px' />
                        <DropdownItemsText>{t('topNavDropdown.userGuide')}</DropdownItemsText>
                      </FirstDropDownRow>
                    </a>
                    <LastDropDownRow onClick={handleLogoutInApp}>
                      <LogOut title='Log Out' height='27px' width='27px' />
                      <DropdownItemsText>{t('topNavDropdown.logOut')}</DropdownItemsText>
                    </LastDropDownRow>
                    <Divider>
                      <Line x1='0%' x2='100%' y1='50%' y2='50%' />
                    </Divider>
                  </DropdownItemsContainer>
                </DropdownBox>
              </div>
            )}
          >
            <div onClick={visible ? hide : show} onKeyDown={visible ? hide : show} role='button' tabIndex={0}>
              <NameContainer isFitTest={isFitTest}>
                <NameText as='div' isFitTest={isFitTest}>
                  {name}
                </NameText>
                {!visible && (
                  <>
                    <ChevronContainer isFitTest={isFitTest}>
                      <ChevronDown title='Dropdown' width='12px' height='7px' />
                    </ChevronContainer>
                    <HamburgerMenuContainer>
                      <Hamburger title='Menu' width='25px' height='17px' />
                    </HamburgerMenuContainer>
                  </>
                )}
                {visible && (
                  <>
                    <ChevronContainer isFitTest={isFitTest}>
                      <ChevronUp title='Dropdown' width='12px' height='7px' />
                    </ChevronContainer>
                    <HamburgerMenuContainer>
                      <Close title='Close Menu' width='28px' height='28px' />
                    </HamburgerMenuContainer>
                  </>
                )}
              </NameContainer>
            </div>
          </Tippy>
        )}
      </StreakAndNameContainer>
      {showWorkout && data && <WorkoutHeader workoutData={data} headerType={headerType} />}
    </BaseContainer>
  )
}

const Container = styled.div<{ sticky: boolean; noTopNav: boolean }>`
  position: ${({ sticky }) => (sticky ? 'sticky' : 'static')};
  top: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  padding: 0 31px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  ${({ noTopNav }) => noTopNav && `display: none`};
`

const StreakContainer = styled.span`
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: none;
  }
`
const ChevronContainer = styled(StreakContainer)<{ isFitTest: boolean }>`
  color: ${({ theme, isFitTest }) => (isFitTest ? theme.colors.constant.white : theme.colors.neutral[400])};
`

const HamburgerMenuContainer = styled.span`
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    display: none;
  }
  color: ${({ theme }) => theme.colors.neutral[300]};
`

const ContainerPrimary = styled(Container)`
  background-color: ${({ theme }) => theme.colors.background.card};
`

const ContainerTransparent = styled(Container)`
  background: none;
  border-color: ${({ theme }) => theme.colors.neutral[100]}3d;
`

const StreakAndNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    align-items: flex-end;
  }
`

const NameText = styled(BodyText1SemiBold)<{ isFitTest: boolean }>`
  padding-right: 14px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    display: none;
  }
  ${({ theme, isFitTest }) => isFitTest && `color: ${theme.colors.constant.white}`};
`

const NameContainer = styled.div<{ isFitTest: boolean }>`
  color: ${({ theme }) => theme.colors.neutral[400]};
  cursor: pointer;
  &:hover {
    background: ${({ theme, isFitTest }) => (isFitTest ? theme.colors.warmGray48 : theme.colors.neutral[100])};
  }
  border-radius: 100px;
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 24px 16px 30px;
  margin-left: 10px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    padding: 0px;
  }
  transition: all 150ms ease-in-out;
`

const DropdownItemsContainer = styled.div`
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: 300px;
  background-color: ${(props) => props.theme.colors.background.card};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    overflow-y: auto;
    overflow-x: hidden;
    width: 90vw;
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }
`

const DropdownItemsText = styled(BodyText1SemiBold)`
  margin-left: 13px;
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    margin-left: 27px;
  }
`

const Divider = styled.svg`
  height: 2px;
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    display: none;
  }
`

const Line = styled.line`
  stroke: ${({ theme }) => theme.colors.neutral[100]};
  stroke-width: 1px;
`

const DropdownRow = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  height: 58px;
  padding: 0 20px;
  color: ${({ theme }) => theme.colors.neutral[300]};
  ${({ theme }) => theme.mediaQuery.maxWidth.mobileLarge} {
    color: ${({ theme }) => theme.colors.neutral[300]};
    background-color: ${({ theme }) => theme.colors.background.card};
  }
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    &:hover {
      background-color: ${({ theme }) => theme.colors.neutral[100]};
      > * {
        color: ${({ theme }) => theme.colors.neutral[400]};
      }
      span {
        color: ${({ theme }) => theme.colors.neutral[400]};
      }
    }
  }
`

const FirstDropDownRow = styled(DropdownRow)`
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
  }
  cursor: pointer;
`

const LastDropDownRow = styled(DropdownRow)`
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
  }
  cursor: pointer;
`

const StyledBrandTitle = styled.div<{ $isWhite?: boolean }>`
  color: ${({ theme, $isWhite }) => ($isWhite ? theme.colors.constant.white : theme.colors.neutral[400])};
`

export default TopNav
