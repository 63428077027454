import React from 'react'

import styled from 'styled-components'

const dropdownPlacementOptions = [
  'top',
  'bottom',
  'right',
  'left',
  'top-start',
  'top-end',
  'bottom-start',
  'bottom-end',
  'right-start',
  'right-end',
  'left-start',
  'left-end',
]

interface DropdownBoxProps {
  placement?: typeof dropdownPlacementOptions[0]
  paddingHorizontal?: number
  paddingVertical?: number
}

interface DropdownDivProps {
  offset: string
  paddingHorizontal: number
  paddingVertical: number
  horizontalProp: string
  verticalProp: string
}

const DropdownBox: React.FC<DropdownBoxProps> = ({
  children,
  placement = '',
  paddingHorizontal = 20,
  paddingVertical = 20,
}) => {
  const props = {
    paddingHorizontal,
    paddingVertical,
    horizontalProp: '',
    verticalProp: '',
    offset: '',
  }

  if (placement.includes('bottom')) {
    props.horizontalProp = 'left'
    props.verticalProp = 'top'
  } else if (placement.includes('top')) {
    props.horizontalProp = 'left'
    props.verticalProp = 'bottom'
  } else if (placement.includes('left')) {
    props.verticalProp = 'right'
    props.horizontalProp = 'top'
  } else if (placement.includes('right')) {
    props.verticalProp = 'left'
    props.horizontalProp = 'top'
  }

  if (placement.includes('-end')) {
    props.offset = 'calc(70% - 12px)'
  } else if (placement.includes('-start')) {
    props.offset = '30%'
  } else {
    props.offset = 'calc(50% - 12px)'
  }

  return <Dropdown {...props}>{children}</Dropdown>
}

const Dropdown = styled.div<DropdownDivProps>`
  border-radius: 16px;
  box-shadow: ${(props) => props.theme.card.shadow};
  display: block;
  min-width: 40px;
  min-height: 40px;
  padding: ${({ paddingVertical, paddingHorizontal }) => `${paddingVertical}px ${paddingHorizontal}px`};
`

export default DropdownBox
