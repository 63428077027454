import { useContext, useEffect } from 'react'

import { AuthContext } from '~/context/AuthContext'
import { useGetAccountStateQuery } from '~/graphql/generated/schema'

type UseUpdateAccountStateOptions = {
  skip: boolean
}

const useUpdateAccountState = ({ skip }: UseUpdateAccountStateOptions = { skip: false }) => {
  const { loading, data } = useGetAccountStateQuery({ skip })
  const { setHasPremium, setIsFreeTrialEligible } = useContext(AuthContext)

  useEffect(() => {
    if (!skip && !loading) {
      const hasPremium = true
      const isFreeTrialEligible = data?.me?.isFreeTrialEligible || false
      setHasPremium(hasPremium)
      setIsFreeTrialEligible(isFreeTrialEligible)
    }
  })
}

export default useUpdateAccountState
