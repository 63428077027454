import { LIMITED_WORKOUT_GAME_TOTAL, PlayContext, PREMIUM_WORKOUT_GAME_TOTAL } from '~/constants'
import { GetTodaysWorkoutQuery } from '~/graphql/generated/schema'

export const mapWorkout = (workout: { id: any; slots: any; createdAt?: any }, _hasPremium = true): Workout => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { __typename, ...slots } = workout.slots
  const mappedWorkout: Workout = {
    id: workout.id,
    createdAt: workout.createdAt,
    slots,
  }

  return mappedWorkout
}

export const getNextUnplayedGame = (
  queryResult: GetTodaysWorkoutQuery,
  hasPremium: boolean,
): {
  workoutSlotKey: null | keyof WorkoutSlots
  nextGame: null | WorkoutGame
} => {
  const { todaysWorkout } = queryResult
  const { slots } = mapWorkout(todaysWorkout, hasPremium)

  const slotKeys = Object.keys(slots).filter((k) => k.startsWith('slot')) as (keyof WorkoutSlots)[]

  const workoutSlotKey = slotKeys.find((key) => !slots[key].lastGamePlay)

  if (!workoutSlotKey) {
    return {
      workoutSlotKey: null,
      nextGame: null,
    }
  }

  return {
    workoutSlotKey,
    nextGame: slots[workoutSlotKey],
  }
}

export const getTotalGamesPlayed = (queryResult: GetTodaysWorkoutQuery) => {
  const { slots } = mapWorkout(queryResult.todaysWorkout)
  // Filter out games that do not have a gameplay and only count those that do
  const totalPlayedGames = Object.values(slots).filter((slot) => {
    return slot.lastGamePlay
  }).length

  return totalPlayedGames
}

export const getTotalGamesCount = (hasPremium: boolean) => {
  return hasPremium ? PREMIUM_WORKOUT_GAME_TOTAL : LIMITED_WORKOUT_GAME_TOTAL
}

export const getCompletedGameSlugs = (queryResult: GetTodaysWorkoutQuery, hasPremium: boolean) => {
  const { slots } = mapWorkout(queryResult.todaysWorkout, hasPremium)
  return Object.values(slots)
    .filter((slot) => slot.lastGamePlay !== null)
    .map((workoutGame) => workoutGame.game.slug as GameSlug)
}

export const handleWorkoutComplete = (
  queryResult: GetTodaysWorkoutQuery,
  hasPremium: boolean,
  playContext: PlayContext,
  callbackFn: (workoutGameSlugs: GameSlug[]) => any,
) => {
  const isWorkoutComplete = getNextUnplayedGame(queryResult, hasPremium).nextGame === null
  if (playContext === PlayContext.Workout && isWorkoutComplete) {
    const gameSlugs = getCompletedGameSlugs(queryResult, hasPremium)
    callbackFn(gameSlugs)
  }
}
