import useRenderUniqString from '~/hooks/useRenderUniqString'

const Flame = ({ title = 'Streak' }: { title: string }) => {
  const id = useRenderUniqString()
  return (
    <svg xmlns='http://www.w3.org/2000/svg' fill='none' width='1em' height='1em' viewBox='10 8.48 20 23.52' role='img'>
      <title>{title}</title>
      <path
        d='M30 22.4828C30 27.739 25.5228 32 20 32C14.4772 32 10 27.739 10 22.4828C10 17.5405 12.6907 11.6489 19.8794 8.55486C20.5417 8.26979 21.2579 8.8076 21.318 9.52613C21.7322 14.4767 25.4505 20.2356 26.334 16.6329C26.5309 15.8298 27.4159 15.4117 27.8937 16.0866C29.174 17.8955 30 20.4789 30 22.4828Z'
        fill={`url(#${id})`}
      ></path>
      <path
        d='M24.7825 27.4054C24.7825 29.9429 22.6413 31.9999 19.9999 31.9999C17.3585 31.9999 15.2173 29.9429 15.2173 27.4054C15.2173 25.3311 17.0649 21.9762 19.1822 20.8203C19.8117 20.4767 20.3127 21.1613 20.2554 21.8761C20.1352 23.3752 20.8811 24.3965 21.9323 23.3754C22.3606 22.9593 23.0039 22.8331 23.3262 23.3358C24.0869 24.522 24.7825 26.4316 24.7825 27.4054Z'
        fill='#FFCD3D'
      ></path>
      <defs>
        <linearGradient id={id} x1='26.5' y1='8' x2='14.7119' y2='30.4107' gradientUnits='userSpaceOnUse'>
          <stop stopColor='#FFCD3D'></stop>
          <stop offset='1' stopColor='#FD6533'></stop>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Flame
