import React from 'react'

import styled from 'styled-components'

import ButtonWithTracking from '~/components/ui/ButtonWithTracking'
import chevron_right from '~/images/chevron_right.svg'

const BackIcon = styled(chevron_right)`
  height: 17px;
  transform: rotate(180deg);
`

const StyledButton = styled(ButtonWithTracking)<{ $isWhite?: boolean }>`
  letter-spacing: 0.1em;
  color: ${({ theme, $isWhite }) => ($isWhite ? theme.colors.constant.white : theme.colors.neutral[300])};
  margin-left: min(3%, 40px);
  min-height: unset;
  padding: 6px 16px;
  width: max-content;
  transition: all 150ms ease-in-out;
  text-transform: uppercase;
  /* A little shadow on the text to help with non-contrasting game hero images */
  text-shadow: 0px 1px 1px rgba(18, 14, 12, 0.07), 0px 1px 4px rgba(18, 14, 12, 0.1);
  :hover {
    border-radius: 200px;
    background-color: ${({ theme }) => theme.colors.constant.white}29;
  }
`

const ButtonBackToGames = ({ onClick, $isWhite }: { onClick?: () => void; $isWhite?: boolean }) => {
  return (
    <StyledButton
      onClick={onClick}
      leftBadge={<BackIcon title='Back' />}
      kind='clean'
      $isWhite={$isWhite}
      textKey='backToGames'
    />
  )
}

export default ButtonBackToGames
