import React from 'react'

import { Subheading2 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import useStreakData from '~/hooks/useStreakData'
import Flame from '~/images/Streak/Flame'

type ActiveInterface = {
  isActive: boolean
}

const StreakCounter = (): JSX.Element => {
  const { loading, error, currentStreakLength } = useStreakData()
  const loaded = !(loading || error)

  const isActive = currentStreakLength > 0
  return (
    <>
      {loaded && (
        <Container currentStreakLength={currentStreakLength}>
          <FlameContainer isActive={isActive}>
            <Flame title='Streak' />
          </FlameContainer>
          <Counter isActive={isActive}>
            <CounterValue>{currentStreakLength}</CounterValue>
          </Counter>
        </Container>
      )}
    </>
  )
}

const Container = styled.div<{ currentStreakLength: number }>`
  display: flex;
  line-height: 40px;
  border-radius: 40px;
  border: 1px solid ${({ theme }) => theme.colors.neutral[100]};
  box-shadow: ${(props) => props.theme.effects.boxShadow.outset[1]};
  background: ${(props) => props.theme.colors.background.card};
  width: ${(props) => (props.currentStreakLength > 999 ? '130px' : props.currentStreakLength > 99 ? '110px' : '80px')};
`

const FlameContainer = styled.div<ActiveInterface>`
  display: flex;
  flex-grow: 1;
  flex-basis: 50%;
  background: ${(props) => props.theme.colors.neutral[100]}7A;
  border-radius: 40px;
  font-size: 21px;
  > svg {
    margin: auto;
  }
`

const Counter = styled.div<ActiveInterface>`
  display: flex;
  flex-grow: 1;
  flex-basis: 50%;
  justify-content: center;
  color: ${({ isActive, theme }) => (isActive ? theme.colors.neutral[400] : theme.colors.neutral[200])};
  padding: 8px;
`

const CounterValue = styled(Subheading2)`
  display: inline-block;
`

export const StreakContainer = styled.div`
  margin-top: 24px;
  ${({ theme }) => theme.mediaQuery.minWidth.mobileLarge} {
    display: none;
  }
`
export default StreakCounter
