import { useMemo } from 'react'

const useRenderUniqString = () => {
  const uniqId = useMemo(() => {
    return (Math.random() + 1).toString(36).substring(7)
  }, [])
  return uniqId
}

export default useRenderUniqString
