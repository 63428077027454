import { forwardRef } from 'react'

import { BodyText2SemiBold, Button } from '@lumoslabs/lumosity-storybook'
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack'
import styled from 'styled-components'

import LeafBubbleCard from '~/components/snackbars/LeafBubbleCard'
import { useTranslationForNamespace } from '~/hooks/useTranslationForNamespace'

interface LogoutConfirmationProps {
  id: SnackbarKey
  message: SnackbarMessage
  handleConfirm: () => Promise<void>
}

const LogoutConfirmation = forwardRef<HTMLDivElement, LogoutConfirmationProps>((props, ref) => {
  const t = useTranslationForNamespace('common')
  const { message, id, handleConfirm, ...other } = props
  const { closeSnackbar } = useSnackbar()
  return (
    <StyledLeafBubbleCard ref={ref} id={id as string} {...other}>
      <BodyText2SemiBold>{message}</BodyText2SemiBold>
      <LogoutActionContainer>
        <Button
          onClick={() => {
            handleConfirm()
            closeSnackbar()
          }}
        >
          {t('buttons.logout')}
        </Button>
        <Button
          onClick={() => {
            closeSnackbar()
          }}
        >
          {t('buttons.stayLoggedIn')}
        </Button>
      </LogoutActionContainer>
    </StyledLeafBubbleCard>
  )
})

const StyledLeafBubbleCard = styled(LeafBubbleCard)`
  background-color: ${({ theme }) => theme.colors.system.secondaryAccent};
`

const LogoutActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
  padding: 15px 0 8px;
  z-index: 1;
`

export default LogoutConfirmation
