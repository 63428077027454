import React from 'react'

import styled from 'styled-components'

import ButtonWithTracking from '~/components/ui/ButtonWithTracking'
import Close from '~/images/close_x.svg'

const CloseIcon = styled(Close)`
  font-size: 19px;
`

const StyledButton = styled(ButtonWithTracking)`
  letter-spacing: 0.1em;
  color: ${({ theme }) => theme.colors.neutral[300]};
  margin-left: min(3%, 40px);
  padding: 6px 16px;
  min-height: unset;
  transition: all 150ms ease-in-out;
  text-transform: uppercase;
  :hover {
    border-radius: 200px;
    background-color: ${({ theme }) => theme.colors.neutral[100]};
  }
`

const ButtonQuitGame = ({ onClick }: { onClick: () => void }) => {
  return <StyledButton onClick={onClick} leftBadge={<CloseIcon title='Close' />} kind='clean' textKey='quitGame' />
}

export default ButtonQuitGame
