import React from 'react'

import { Heading2 } from '@lumoslabs/lumosity-storybook'
import styled from 'styled-components'

import { CardShadowRadius } from '~/components/ui/sharedStyledComponents'

export interface CardProps {
  children: React.ReactNode
  className?: string
}

const Card = ({ children, className }: CardProps): JSX.Element => (
  <CardShadowRadius className={className}>{children}</CardShadowRadius>
)

Card.Body = styled.div`
  padding: 36px 25px;
`

Card.Head = styled.div`
  color: ${({ theme }) => theme.colors.neutral[300]};
  padding: 18px;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.1em;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral[100]};
`

Card.Title = Heading2

Card.Hr = styled.hr`
  border: 1px solid ${(props) => props.theme.colors.neutral[100]};
  margin: 24px 0;
`

export default Card
